<template>
  <div class="page-accounts">
    <div class="columns is-gapless is-vh">
      <div class="column is-2 has-background-dark">
        <Sidebar/>
      </div>

      <div class="column">
        <div class="section">
          <h1 class="title">Quizzes Overview</h1>

          <hr>

          <QuizzesOverview/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import QuizzesOverview from '@/components/QuizzesOverview.vue'

export default {
  name: 'Quizzes',
  components: {
    Sidebar,
    QuizzesOverview,
  }
}
</script>
